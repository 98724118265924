
import '@blueprintjs/core/lib/css/blueprint.css';
import { Button, Drawer, DrawerSize, Radio, RadioGroup, Spinner, Navbar, Alignment, InputGroup, Popover  } from '@blueprintjs/core';
import "./main.css";
import { observer } from 'mobx-react-lite';
import {FileMenu} from './menu/file'
import {CreateDialog} from './menu/create'
import {React} from 'react'

const Header = observer(({store, saving, project})  => { 
    
    return ( 
      
      <div >  
        <Navbar style={{zIndex: 11}} className="header" >
          <Navbar.Group  style={{width:'430px'}} align={Alignment.LEFT}> 
              <img src="https://static.consultadoctor.ro/images/logo-editor.jpg" height="40" style={{marginRight:10}}  />
              <Popover content={<FileMenu store={store} project={project} />} minimal={true} popoverClassName="file-menu-popover" placement="bottom">
                  <Button minimal={true} large={true}     text="Fișier" />
              </Popover>
          </Navbar.Group>
          <Navbar.Group align={Alignment.LEFT}>
         
              <Navbar.Divider />
              <Button minimal={true} disabled={!store.history.canUndo} onClick={()=>store.history.undo()} large={true} icon="undo"  />
              <Button minimal={true} disabled={!store.history.canRedo} onClick={()=>store.history.redo()} large={true} icon="redo"  />
              <Navbar.Divider />
              <Button minimal={true} large={true} loading={store.saving} icon="cloud-tick"  />
            
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <div className="project-name" >
              <div  contenteditable="true" onBlur={e=>{ if(e.target.textContent == '') e.target.textContent = "Proiectul meu"; project.set({name:e.target.textContent})  }}  >
                <span>{project.value?.name}</span>
              </div>
            </div>
            <Button intent="success" large={true} outlined={true} className="me-2 rounded " icon="shopping-cart" text="Comandă" />
            <Button intent="primary" large={true}  className="rounded" icon="cloud-download" text="Descarcă" />
           
          </Navbar.Group>

      </Navbar>
      
      <CreateDialog store={store} project={project} />
      
      </div>
    );
});  
export {Header};