import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup, ControlGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid, Image } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {Element} from './image' 
import { PreviewPanel } from "../components/panel/preview";
const splitChunks = (inputArray, perChunk = 3) => {
  return inputArray.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/perChunk)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}
const ViewPanel =  observer(({ store, panel }) => {
  
  let [query, setQuery] = React.useState(panel.value.query);
  let [image, setImage] = React.useState({});
  let [elements, setElements] = React.useState([]);

  let [images, setImages] = React.useState([]);
  let [page, setPage] = React.useState(1);
  let [totalPages, setTotalPages] = React.useState(0);
  let [loading, setLoading] = React.useState(true);

    
  const onSearch = e=>{
    if(e.key != "Enter") return
    setQuery(old=>e.target.value)
    panel.set({query: e.target.value, back:{
      ...panel.value.back,
      query: e.target.value
    }})

  }
  let i = 0;

  const loadImages = async  (query) => {
      // if reach the end, we stop
    if(totalPages!=0 && page>totalPages){
      return
    }
    // mark as loading
    setLoading(true)

    // grab images
    const result = await fetch(`${Globals.api}${Globals.urls.viewElements}${panel.value.id}?page=${page}&query=${query}`) 
    const data = await result.json();  
    console.log('data', data)
    for(let item of data.results) setImages(i=>[...i, item]);

    // finish loading
    setLoading(false)
    
    // set the total of pages accordingly
    if(page==1 && query == data.query) setTotalPages(data.total_pages)

    // increment page number
    setPage(p=>p+1)
  }

  // this is called when scrolled reached end aka infinite scroll
  const loadMore = (p) => {
    // if not already loading, start load images
    if(!loading) loadImages(query);
    return true;
  }
  

  React.useEffect( () => { 
    page = 1;
    setQuery(q=>panel.value.query)
    query = panel.value.query;
    setPage(o=>1)
    totalPages = 1;
    images=[];
    setImages(old=>[]);  
    loadImages(query);
  
}, [query, image, panel]);


 
 

  let backButton = (<div></div>);

  if(panel.value.back)
    backButton = (<Button onClick={e=>panel.set(panel.value.back)} minimal={true} icon="arrow-left" />)

  return (
    <div className="element-panel h-100"  >
        <div className="p-3 d-flex align-items-center pb-0">
          {backButton}
          <h6 className="ms-2 mb-0">{panel.value.title}</h6>
        </div>
        <div className="p-3 d-flex">
          <FormGroup fill={true} className="mb-0" labelFor="name" >
              <InputGroup leftIcon="search" large={true} defaultValue={query}   placeholder="Caută elemente aici..." onKeyUp={onSearch}  /> 
          </FormGroup>
        </div>
        <div className="overflow-auto"  style={{'height':'calc(100% - 70px)'}}>
          <div className="px-3"> 
            <div className="row m-0">
            {splitChunks(images)?.map((images, index) =>  (
              <Element store={store} panel={panel} images={images} />
            ))}
            </div>

          </div>
        </div>
    </div>
  )
}) 

 

  
 
export {ViewPanel};