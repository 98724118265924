import '@blueprintjs/core/lib/css/blueprint.css';
import { SidePanelWrap } from 'maria'; 
import { SidePanel } from 'maria/side-panel';  
import { TextSection, PagesSection, PhotosSection, ElementsSection, BackgroundSection, SizeSection, LayersSection } from 'maria/side-panel';
import { useState } from 'react';
import {AdminSection} from './sidebars/admin.jsx'
import {TextEditorSection} from './sidebars/text-editor.jsx'
import { PhotoSection } from './sidebars/images.jsx'
import { UploadSection } from './panels/image/upload.jsx'
import { TemplatesSection } from './panels/templates/index.jsx'

import { CustomElementsSection } from './panels/elements/index.jsx'




var mySections = [  TemplatesSection,   CustomElementsSection, TextSection,   UploadSection, ElementsSection,  /* UploadSection, */ BackgroundSection, LayersSection,  SizeSection  ];
let interval;

let Sidebar = ({store})  => {
 
 
    return (
      <div  > 
      <SidePanelWrap >
        <SidePanel className="bg-dark p-3" store={store}  sections={mySections} defaultSection="custom" />
      </SidePanelWrap>
      </div>
    );
};  
export {Sidebar};