
import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup, ControlGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid, Image } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {onSelectImage} from './select' 
import { HomePanel } from "./home";
import { ViewPanel } from "./view";

import { useState } from 'react';
class Panel{
    data;
    constructor(object){
      this.data = useState(object);
    }
    get value(){
        return this.data[0];
    }
    set(v){

      console.log(v)
      this.data[1]({...this.data[0], ...v});
    
    }
  }

const IndexPanel =  observer(({ store }) => {
   
  const panel = new Panel({
    name: 'home'
  });
  
  if(panel.value.name == 'home')
    return <HomePanel store={store} panel={panel} />
  if(panel.value.name == 'collection')
    return <ViewPanel store={store} panel={panel} />
  
})
 
// define the new custom section
const CustomElementsSection = {
  name: 'custom-elements',
  Tab: (props) => (
    <SectionTab name="Elemente" {...props}>
      <Icon icon="shapes" />
    </SectionTab>
  ),
  Panel: IndexPanel,
};

export {CustomElementsSection};