
import { FormGroup, InputGroup, NumericInput , MenuDivider, Dialog, DialogBody, DialogFooter, Button, HTMLSelect  } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {Http, Globals} from '../../http'
import './file.css'
import slugify from 'slugify'
const CreateDialog = observer(({store,  project})  => { 
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const clickClose = ()=>{
        project.set({creating:false})
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        store.clear();
        setErrors({});
        setLoading(true)
        const data = Object.fromEntries(new FormData(e.target).entries());
      
        const response = await Http.post(Globals.urls.createProject, {
            name: data.name,
            unit: data.unit,
            width: parseFloat(data.width),
            height: parseFloat(data.height), 
        })
        setLoading(false)
        if (response.status == 200){
            project.set({name: data.name, creating:false})
            const page = store.addPage(response.page);
            history.pushState({}, null, `/design/${response.uid}`);
            console.log(store.activePage)
        }
        
        if(response.status == 400)
            setErrors(response);

        return false;
    }
    
    return ( 
        <Dialog isOpen={project?.value?.creating}  hasBackdrop={false}   >
            <form action="" onSubmit={onSubmit}>
                <DialogBody>
                <h5> Creeaza un design nou </h5>

                    <FormGroup label="Denumire" labelFor="name"  intent={errors?.name?'danger':'default'}  helperText={errors?.name? errors?.name[0]:null} >
                        <InputGroup large={true}  id="name" name="name"  placeholder="Proiectul meu" />
                    </FormGroup>
                    <div className="row">
                        <div className="col-md">
                            <FormGroup  label="Lungime" labelFor="width" intent={ errors?.width? 'danger':'default'}  helperText={errors?.width? errors?.width[0]:null} >
                                <NumericInput fill={true} large={true}  stepSize={100}  minorStepSize={1} id="width" name="width" placeholder="1000" />
                            </FormGroup>
                        </div>
                        <div className="col-md">
                            <FormGroup label="Latime" labelFor="height" intent={ errors?.height? 'danger':'default'}  helperText={errors?.height? errors?.height[0]:null}  >
                                <NumericInput fill={true} large={true}  stepSize={100} minorStepSize={1} name="height" id="height" placeholder="600" />
                            </FormGroup>
                        </div>
                        <div className="col-md-3">
                            <FormGroup label="Unitate " labelFor="unit" intent={ errors?.unit? 'danger':'default'}  helperText={errors?.unit? errors?.unit[0]:null}  >
                                <HTMLSelect  id="unit" fill={true} large={true}  name="unit">
                                    <option value="mm">mm</option>
                                    <option value="cm">cm</option>
                                    <option value="px">px</option>
                                    <option value="in">in</option>
                                </HTMLSelect>
                            </FormGroup>
                        </div>
                    </div> 
                </DialogBody>
                <DialogFooter actions={[
                    <Button intent="default"  large={true}  className="rounded" text="Renunta" onClick={clickClose} />,
                    <Button intent="primary" loading={loading}  large={true} className="rounded" text="Creeaza" type="submit"  />
                ]} />
            </form>

        </Dialog>
    );
});  
export {CreateDialog};