import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup, ControlGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid, Image } from 'maria/side-panel/images-grid';
import { Globals } from '../../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import { getImageSize } from 'maria/utils/image';
const onSelectImage = async (store,image, pos, element, event) => {
    // get image size
    console.log(image,pos,element,event)
    const { width, height } = await getImageSize(image.thumbnail);
    const ratio = store.activePage.width/width  / 2;
    store.activePage.addElement({
      type: image.type,
      src: image.original,
      width: width * ratio ,
      height: height * ratio ,
      x: pos?.x || 0,
      y: pos?.y || 0,
    }); 
  };
const PreviewPanel =  observer(({ store, item, panel, hideTitle:hideTitle, onClickViewMore:onClickViewMore}) => {
    const [scrollLeft, setScrollLeft] = React.useState(0);
    const scroll = React.useRef(null);
 
  
    const clickNext = e=>{
      const sl = scroll.current.scrollLeft + scroll.current.clientWidth - 50;
      setScrollLeft(o=>sl)
      scroll.current.scroll({top: 0, left: sl, behavior: 'smooth' })
  
    }
     
    const clickPrev = e=>{
      let sl = scroll.current.scrollLeft - scroll.current.clientWidth +50
      if(sl<0) sl=0
      setScrollLeft(p=>sl)
      scroll.current.scroll({top: 0, left:sl , behavior: 'smooth' })
    }
     
    
   

    return (
        <div className=" element-panel">
            <div class={hideTitle?'d-none':''}>
                <a onClick={e=>{panel.set({name:'collection', title: item.name,  id:item.id, back: panel.value }); if(onClickViewMore) onClickViewMore()}} className="text-dark d-flex mb-3">
                    <span className=" fw-bold">{item.name}  </span>
                    <span className="ms-auto small" >Vezi tot</span>
                </a>
            </div>
            <div  className="  position-relative"  >
                <div onClick={clickPrev} className="position-absolute h-100  h-100 align-items-center" style={{'display':(scrollLeft == 0?'none':'flex'), 'cursor':'pointer', 'left':0, 'zIndex':10, 'top': 0, 'background':'linear-gradient(-271deg, white, transparent)'}}>
                    <Icon className="pe-3" icon="chevron-left" />
                    
                </div>

                
                <div ref={scroll} className="overflow-hidden" style={{'height': '80px'}}>
                    <ControlGroup fill={false} vertical={false}>
                    {item.elements?.map((image, index) => (
                        <div className="position-relative element">
                            <Image onSelect={(i,p,e)=>onSelectImage(store, image,p,e)} className="me-2" 
                                style={{'width':'auto', 'height': '70px'}} 
                                shadowEnabled={false} 
                                url={image.thumbnail} />
                            <Button minimal={true}   small={true} icon="more" style={{'top':0, 'right':0}} className="position-absolute rounded more" />
                        </div>
                    ))}
                     </ControlGroup>
                </div>

                <div onClick={clickNext}  className="position-absolute h-100 d-flex h-100 align-items-center" style={{'cursor':'pointer', 'right':0, 'zIndex':10, 'top': 0, 'background':'linear-gradient(271deg, white, transparent)'}}>
                    <Icon   className="ps-3" icon="chevron-right" />
                </div>
            </div>
        </div>
    )
  })
   
export {PreviewPanel};
