
import { Menu, MenuItem, MenuDivider, Dialog, DialogBody, DialogFooter, Button  } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

import './file.css'
import slugify from 'slugify'
const FileMenu = observer(({store, saving, project})  => { 
    const [downloading, setDownloading] = React.useState(false);
   
    const handleClick = ()=>{
        console.log(store.activePage)
    }
    const createClick = ()=>{
        project.set({creating:true});
    }
    const downloadClick = async ()=>  {
        
        const name = slugify(project.value.name.toLowerCase());
        await store.saveAsPDF({ unit:'cm', dpi: 96, includeBleed: true, pixelRatio: 10,  fileName: `${name}.pdf` }); 
    }
    
    return ( 
        <div>
       
        <Menu className="file-menu" style={{width:300}}>
            <div className="p-3 pb-2">
                <h5>{project.value?.name}</h5>
                <div className="small mt-2">
                    Carte de vizita &bull; {store.width}{store.unit} x {store.height}{store.unit} 
                </div>
            </div>
            <MenuDivider />
            <MenuItem icon="new-text-box" onClick={createClick} text="Creează design nou" />
            

            <MenuDivider />

            <MenuItem  text="Setări" icon="cog"   >
                <MenuItem shouldDismissPopover={false} icon={store.bleedVisible?'tick':''}    onClick={()=>{store.toggleBleed(); project.set({bleed:store.bleedVisible, rules:store.rulesVisible})}} text="Margine taiere pentru imprimare (bleed)" />
                <MenuItem shouldDismissPopover={false} icon={store.rulesVisible?'tick':''}     onClick={()=>{store.toggleRulers(); project.set({bleed:store.bleedVisible, rules:store.rulesVisible})}} text="Rigle si Ghidaje" />
            </MenuItem>
            <MenuDivider />
    
            <MenuItem icon="cloud-tick" onClick={handleClick} text="Salvează" />
            <MenuItem icon="clipboard" onClick={handleClick} text="Fă o copie" />
            <MenuItem icon="download" onClick={downloadClick} text="Descarcă" />
            <div className="p-2"></div>
            
        </Menu>
        </div>
    
      
    );
});  
export {FileMenu};