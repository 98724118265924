import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup, ControlGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid, Image } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {onSelectImage} from './select'
import { PreviewPanel } from "../components/panel/preview";

const Element =  observer(({ store, panel, images }) => {
   
  let [loading, setLoading] = React.useState(false);
  let [selectedImage, setImage] = React.useState(null);
  let [item, setItem] = React.useState({});

  let className = "col-md-12 p-1 categories "+images.map(e=>e.id).join(' ')
  React.useEffect( ()=>{
    if(!selectedImage) return;

    setTimeout(async () => {
      const result = await fetch(`${Globals.api}${Globals.urls.listElements}?image=${selectedImage.id}`);
      const data = await result.json();
      setItem(data.results[0])
    }, 0);    
  }, [selectedImage])

  return (
    <React.Fragment>
        {images?.map((image, index) =>  (

          <div className="col-md-4 p-1  overflow-hidden">
            <div className="position-relative  element">
              <Image onSelect={(i,p,e)=>{
                [...document.getElementsByClassName('categories')].map(i=>{   i.style.display = 'block'; if(!i.classList.contains(`${image.id}`)) i.style.display = 'none'; })
                setImage(image);
                onSelectImage(store, image,p,e)
              }} className="me-2" 
                  style={{'width':'100%', 'height': '70px'}} 
                  shadowEnabled={false} 
                  url={image.thumbnail} />
              <Button minimal={true}    small={true} icon="more" style={{'top':0, 'right':0}} className="position-absolute rounded more" />
                
            </div>
          </div>
        ))}

    <div class={className}  style={{'display':'none'}}>
      <div className="bg-secondary p-2 bg-opacity-25 rounded">
        <div className="d-flex">
          <Icon icon="clean" className="me-2" />
          <strong>Din aceeasi colectie</strong>
          <a className="ms-auto" onClick={e=>{
              panel.set({name:'collection', title: item.name,   id:item.id, back: panel.value , query:'' });
          }}>Vezi tot</a>
        </div>
        <div className="mt-2">
          <PreviewPanel store={store} item={item} hideTitle={true} panel={panel}  />
        </div>
      </div>
    </div>
    </React.Fragment>
  )
}) 

 

  
 
export {Element};