import { useState } from 'react';
class Project{
    data;
    constructor(object){
      this.data = useState(object);
    }
    get value(){
        let project = window.localStorage.getItem('project');
        try{return JSON.parse(project);}catch{return {}}
        return this.data[0];
    }
    set(v){
      this.data[1]({...this.data[0], ...v});
      window.localStorage.setItem('project', JSON.stringify({...this.data[0], ...v}))
    

    }
  }
export {Project}