import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { SidePanel, SectionTab } from 'maria/side-panel';
import '@blueprintjs/core/lib/css/blueprint.css';


const TextEditorSection = {
    name: 'text-editor',
    visibleInList: false,
    Tab: (props) => (
      <SectionTab name="Admin" {...props}>
        <svg aria-hidden="true" width="20" height="20" focusable="false" data-prefix="fac" data-icon="web-awesome" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16"   ><path fill="currentColor" d="M11.63 1.625C11.63 2.27911 11.2435 2.84296 10.6865 3.10064L14 6L17.2622 5.34755C17.0968 5.10642 17 4.81452 17 4.5C17 3.67157 17.6716 3 18.5 3C19.3284 3 20 3.67157 20 4.5C20 5.31157 19.3555 5.9726 18.5504 5.99917L15.0307 13.8207C14.7077 14.5384 13.9939 15 13.2068 15H6.79317C6.00615 15 5.29229 14.5384 4.96933 13.8207L1.44963 5.99917C0.64452 5.9726 0 5.31157 0 4.5C0 3.67157 0.671573 3 1.5 3C2.32843 3 3 3.67157 3 4.5C3 4.81452 2.9032 5.10642 2.73777 5.34755L6 6L9.31702 3.09761C8.76346 2.83855 8.38 2.27656 8.38 1.625C8.38 0.727537 9.10754 0 10.005 0C10.9025 0 11.63 0.727537 11.63 1.625Z" ></path></svg>
      </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }) => {
      let categories = [{id:2, name:'Vafe'}];
      let selected = store.selectedElements[0]
      if(selected)
      return (
        <div className="p-3 bg-white h-100 element-editor">
           
          <div className="form-group mb-3">
            <p className="text-muted">
                Modificare  text
            </p>
            <textarea name="" id="" value={selected.text} onChange={e => {
                selected.set({ text: e.target.value })
            }} className="form-control"> 
      
            </textarea>

            <a href="#" className="btn btn-primary d-block w-100 mt-3" onClick={e=>{store.selectElements([])}}> Gata </a>
          </div>
           
        </div>
      );
    }),
  };

 

  export  {TextEditorSection};