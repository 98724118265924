import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {onSelectImage} from './select'
import {ImageList} from './grid'
const Panel =  observer(({ store }) => {
  const [query, setQuery] = React.useState('');
  const onSearch = e=>{
    if(e.key != "Enter") return
    setQuery(old=>e.target.value)
  }
   
  
  return (
    <div className="images h-100 "  >
 
        <div className="p-3">
          <FormGroup className="mb-0" labelFor="name" >
              <InputGroup leftIcon="search" large={true}  placeholder="Caută în template-uri..." onKeyUp={onSearch}  /> 
          </FormGroup>
        </div>
    
        <div className="overflow-auto"  style={{'height':'calc(100% - 135px)'}}>
          <ImageList store={store}  query={query}   />
        </div>
 
    </div>
  )
})
 
// define the new custom section
const TemplatesSection = {
  name: 'templates',
  Tab: (props) => (
    <SectionTab name="Modele" {...props}>
      <Icon icon="control" />
    </SectionTab>
  ),
  Panel: Panel,
};

export {TemplatesSection};