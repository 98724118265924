import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {onSelectImage} from './select'
import {ImageList} from './list'
const ImagesPanel =  observer(({ store }) => {
  const [query, setQuery] = React.useState('');
  const [image, setImage] = React.useState({});
  const file = React.useRef(null);
  const onSearch = e=>{
    if(e.key != "Enter") return
    setQuery(old=>e.target.value)
  }
  let i = 0;
   
  const onFileSelected = async (e)=>{
    if(e.target.files.length == 0 ) return;
    const file = e.target.files[0];
    const myToaster = OverlayToaster.create({ position: "bottom" });
    let uploadedImages = 0;
    for(const file of e.target.files)
    {
      const formData = new FormData();
      const name = file.name 
      const ext = name.substring(name.lastIndexOf('.') + 1);
  
      formData.append("original", file, Globals.makeID(25)+"."+ext);
      formData.append("keyword", name.replace(`.${ext}`,''));
      
      const response = await fetch(`${Globals.api}${Globals.urls.uploadImage}`, {
          method: 'POST',
          body: formData,
        });
      if(response.ok)
        uploadedImages++
      
    }
    setImage(i++)
    if(uploadedImages == e.target.files.length)
    myToaster.show({ 
      message:'Imaginile au fost incarcate cu succes!',
      intent: 'success',
      icon: 'check'
    });
  } 

  return (
    <div className="images h-100 "  >
 
        <div className="p-3">
          <FormGroup  labelFor="name" >
              <InputGroup leftIcon="search" large={true}  placeholder="Caută în imaginile incărcate..." onKeyUp={onSearch}  /> 
          </FormGroup>
          <input type="file" multiple="true"  ref={file} onChange={onFileSelected} style={{'position':'fixed', top:'-100em'}} />
          <Button  large={true} intent="primary" fill={true} text="Selectează fișiere" className="mb-2 rounded" onClick={e=>file.current?.click()} />
        </div>
    
        <div className="overflow-auto"  style={{'height':'calc(100% - 135px)'}}>
          <ImageList store={store}  query={query} image={image} />
        </div>
 
    </div>
  )
})
 
// define the new custom section
const UploadSection = {
  name: 'uploads',
  Tab: (props) => (
    <SectionTab name="Incarcari" {...props}>
      <Icon icon="cloud-upload" />
    </SectionTab>
  ),
  Panel: ImagesPanel,
};

export {UploadSection};