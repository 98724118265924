
import { setTranslations } from 'maria/config';
setTranslations({
     
   toolbar: {
        duration: 'Durata',
        opacity: 'Transparenta',
        effects: 'Efecte',
        blur: 'Blur',
        colorPicker:"Culoare element",
  //     textBackground: '',
  //     backgroundCornerRadius: '',
  //     backgroundOpacity: '',
  //     backgroundPadding: '',
        brightness: 'Brightness',
  //     sepia: '',
  //     grayscale: '',
      textStroke: 'Bordura',
      shadow: 'Umbra',
         border: 'Bordura',
         cornerRadius: 'Rotunjire',
         position: 'Pozitionare',
  //     layering: '',
        toForward: 'Deasupra tututor',
        up: 'Sus',
        down: 'Jos',
        toBottom: 'Sub Toate',
        alignLeft: 'Stanga',
        alignCenter: 'Centru',
        alignRight: 'Dreapta',
        alignTop: 'Sus',
        alignMiddle: 'Mijloc',
        alignBottom: 'Jos',
      flip: 'Oglindire',
      flipHorizontally: 'Oglindire orizontal',
      flipVertically: 'Oglindire Vertical',
        fitToBackground: 'Fit',
         removeBackground: null,
  //     removeBackgroundTitle: '',
  //     cancelRemoveBackground: '',
  //     confirmRemoveBackground: '',
      crop: 'Decupeaza',
      cropDone: 'Gata',
      cropCancel: 'Renunta',
       removeClip: 'Elimina forma',
       removeMask: 'Elimina masca',
      transparency: 'Transparenta',
  lockedDescription: 'Elementul este blocat. Deblocheaza-l acum.',
      unlockedDescription: 'Elementul este deblocat. Blocheaza-l pentru a nu putea fi modificat.',
      removeElements: 'Sterge',
      duplicateElements: 'Fa o copie',
  //     download: '',
  //     saveAsImage: '',
  //     saveAsPDF: '',
  //     lineHeight: '',
  //     letterSpacing: '',
  //     offsetX: '',
  //     offsetY: '',
      color: 'Culoare',
      selectable: 'Se poate selecta',
      draggable: 'Se poate muta',
      removable: 'Se poate sterge',
      resizable: 'Se poate redimensiona',
      contentEditable: 'Se poate modifica textul',
      styleEditable: 'Se poate modifica stilul',
      alwaysOnTop: 'Tot timpul deasupra',
      showInExport: 'Arata in export',
      ungroupElements: 'Degrupeaza',
        groupElements: 'Grupeaza',
  //     lineSize: '',
  //     fade: '',
  //     move: '',
  //     zoom: '',
  //     animate: '',
  //     spaceEvenly: '',
  //     horizontalDistribution: '',
  //     verticalDistribution: '',
  //     strokeWidth: '',
  },
  workspace: {
      noPages: 'Nu sunt pagini adaugate',
      addPage: 'Adauga pagina',
      removePage: 'Sterge pagina',
      duplicatePage: 'Duplica paginca',
      moveUp: 'Muta sus',
      moveDown: 'Muta jos',
  },
  scale: {
      reset: 'Reseteaza',
  },
  // error: {
  //     removeBackground: '',
  // },
  sidePanel: {
      templates: 'Modele',
      searchTemplatesWithSameSize: 'Cauta modele de aceeași dimensiune',
      searchPlaceholder: 'Caută',
      // otherFormats: '',
      noResults: 'Nu am gasit rezultate',
      error: 'Nu am gasit rezultate',
      text: 'Text',
      // uploadFont: '',
      myFonts: 'Fonturile mele',
      photos: 'Imagini',
      elements: 'Forme',
      shapes: 'Forme',
      lines: 'Linii',
      upload: 'Incarca',
      uploadImage: 'Incarca imagine',
      uploadTip: 'Vrei sa incarci propriile imagini?',
      background: 'Fundal', 
      resize: 'Dimensiuni',
      layers: 'Straturi',
      // layerTypes: {
      //     image: '',
      //     text: '',
      //     svg: '',
      //     line: '',
      //     figure: '',
      //     group: '',
      // },
    layersTip: 'Ordoneaza elementele in pagina',
    // noLayers: '',
    // namePlaceholder: '',
    // useMagicResize: '',
    // width: '',
    // height: '',
    // magicResizeDescription: '',
     headerText: 'Textul tau aici',
    createHeader: 'Adaugă un text',
    // subHeaderText: '',
    // createSubHeader: '',
    // bodyText: '',
    // createBody: '',
}
});
