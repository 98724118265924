"use strict";
var __createBinding = this && this.__createBinding || (Object.create ? function(e, t, o, n) {
        void 0 === n && (n = o);
        var s = Object.getOwnPropertyDescriptor(t, o);
        s && !("get" in s ? !t.__esModule : s.writable || s.configurable) || (s = {
            enumerable: !0,
            get: function() {
                return t[o]
            }
        }), Object.defineProperty(e, n, s)
    } : function(e, t, o, n) {
        void 0 === n && (n = o), e[n] = t[o]
    }),
    __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function(e, t) {
        Object.defineProperty(e, "default", {
            enumerable: !0,
            value: t
        })
    } : function(e, t) {
        e.default = t
    }),
    __importStar = this && this.__importStar || function(e) {
        if (e && e.__esModule) return e;
        var t = {};
        if (null != e)
            for (var o in e) "default" !== o && Object.prototype.hasOwnProperty.call(e, o) && __createBinding(t, e, o);
        return __setModuleDefault(t, e), t
    };
Object.defineProperty(exports, "__esModule", {
    value: !0
}), exports.injectCustomFont = exports.injectGoogleFont = exports.setGoogleFontsVariants = exports.loadFont = exports.isFontLoaded = exports.replaceGlobalFonts = exports.removeGlobalFont = exports.addGlobalFont = exports.globalFonts = exports.getFontsList = exports.setGoogleFonts = exports.isGoogleFontChanged = void 0;
const mobx = __importStar(require("mobx")),
    loader_1 = require("./loader");
let GOOGLE_FONTS = mobx.observable([
    "Roboto",
    "Open Sans",
    "Montserrat",
    "Poppins",
    "Lato",
    "Roboto Condensed",
    "Inter",
    "Oswald",
    "Roboto Mono",
    "Raleway",
    "Nunito Sans",
    "Rubik",
    "Nunito",
    "Ubuntu",
    "Playfair Display",
    "Merriweather",
    "Roboto Slab",
    "PT Sans",
    "Kanit",
    "Work Sans",
    "Lora",
    "DM Sans",
    "Fira Sans",
    "IBM Plex Sans",
    "Quicksand",
    "Mulish",
    "Barlow",
    "Manrope",
    "Heebo",
    "Titillium Web",
    "PT Serif",
    "Karla",
    "Libre Franklin",
    "Mukta",
    "Nanum Gothic",
    "Inconsolata",
    "Hind Siliguri",
    "Josefin Sans",
    "Arimo",
    "Archivo",
    "Libre Baskerville",
    "Bebas Neue",
    "Dosis",
    "Cairo",
    "Abel",
    "Space Grotesk",
    "Jost",
    "Source Code Pro",
    "PT Sans Narrow",
    "EB Garamond",
    "Oxygen",
    "Anton",
    "Outfit",
    "Dancing Script",
    "Bitter",
    "Teko",
    "Cabin",
    "Overpass",
    "Barlow Condensed",
    "Exo 2",
    "Pacifico",
    "Hind",
    "Prompt",
    "Assistant",
    "Comfortaa",
    "Lobster",
    "Crimson Text",
    "Signika Negative",
    "Sora",
    "Fjalla One",
    "M PLUS Rounded 1c",
    "Zilla Slab",
    "IBM Plex Mono",
    "Rajdhani",
    "Inria Sans",
    "Play",
    "Chakra Petch",
    "Public Sans",
    "Maven Pro",
    "Figtree",
    "Source Sans 3",
    "Varela Round",
    "Archivo Black",
    "Caveat",
    "IBM Plex Sans Arabic",
    "Cormorant Garamond",
    "Red Hat Display",
    "Asap",
    "Permanent Marker",
    "DM Serif Display",
    "Slabo 27px",
    "Arvo",
    "Abril Fatface",
    "Shadows Into Light",
    "Barlow Semi Condensed",
    "Fira Sans Condensed",
    "Almarai",
    "Tajawal",
    "Lilita One",
    "Plus Jakarta Sans",
    "Lexend",
    "Nanum Gothic Coding",
    "Hind Madurai",
    "Questrial",
    "M PLUS 1p",
    "Nanum Myeongjo",
    "Indie Flower",
    "IBM Plex Serif",
    "Yanone Kaffeesatz",
    "Domine",
    "Rowdies",
    "Merriweather Sans",
    "Concert One",
    "Asap Condensed",
    "Orbitron",
    "Source Serif 4",
    "Catamaran",
    "Russo One",
    "Satisfy",
    "Didact Gothic",
    "Archivo Narrow",
    "Signika",
    "Chivo",
    "Cinzel",
    "Sarabun",
    "Vollkorn",
    "Acme",
    "Oleo Script",
    "Urbanist",
    "Roboto Flex",
    "Philosopher",
    "Marcellus",
    "Saira Condensed",
    "Exo",
    "Frank Ruhl Libre",
    "Alegreya",
    "Bree Serif",
    "Alfa Slab One",
    "Amatic SC",
    "Silkscreen",
    "Montserrat Alternates",
    "Black Ops One",
    "Readex Pro",
    "Kalam",
    "Cormorant",
    "Amiri",
    "Alegreya Sans",
    "ABeeZee",
    "Rubik Bubbles",
    "Libre Caslon Text",
    "Be Vietnam Pro",
    "Tinos",
    "Spectral",
    "Neuton",
    "Great Vibes",
    "Changa",
    "Passion One",
    "Lobster Two",
    "Righteous",
    "Prata",
    "Courgette",
    "Patua One",
    "PT Sans Caption",
    "Arsenal",
    "Lexend Deca",
    "Sawarabi Mincho",
    "Ubuntu Mono",
    "Mate",
    "Martel",
    "Noticia Text",
    "Alice",
    "Ubuntu Condensed",
    "Cardo",
    "Zen Kaku Gothic New",
    "Cantarell",
    "Alata",
    "Fraunces",
    "Yellowtail",
    "Bodoni Moda",
    "Khand",
    "Sacramento",
    "Luckiest Guy",
    "Yantramanav",
    "Francois One",
    "Old Standard TT",
    "Kaushan Script",
    "Space Mono",
    "Crete Round",
    "Sawarabi Gothic",
    "DM Serif Text",
    "El Messiri",
    "Encode Sans",
    "Josefin Slab",
    "Gruppo",
    "Saira",
    "Cuprum",
    "Bungee",
    "Encode Sans Condensed",
    "Inter Tight",
    "Gothic A1",
    "Zeyada",
    "Crimson Pro",
    "Antic Slab",
    "Pathway Gothic One",
    "Advent Pro",
    "Quattrocento",
    "Quattrocento Sans",
    "Paytone One",
    "Unbounded",
    "Architects Daughter",
    "Mate SC",
    "Gloria Hallelujah",
    "IBM Plex Sans Condensed",
    "Alegreya Sans SC",
    "Commissioner",
    "Unna",
    "Special Elite",
    "Yeseva One",
    "Literata",
    "League Spartan",
    "Rokkitt",
    "Gelasio",
    "Tangerine",
    "Yatra One",
    "Roboto Serif",
    "Rubik Mono One",
    "Itim",
    "Cookie",
    "Poiret One",
    "Libre Barcode 39",
    "Unica One",
    "Tenor Sans",
    "Merienda",
    "Carter One",
    "Fira Sans Extra Condensed",
    "Sen",
    "Abhaya Libre",
    "Taviraj",
    "News Cycle",
    "Kosugi Maru",
    "Playfair Display SC",
    "Aleo",
    "Mitr",
    "Sanchez",
    "Bangers",
    "Mukta Malar",
    "Amaranth",
    "Courier Prime",
    "Adamina",
    "Titan One",
    "Staatliches",
    "Allura",
    "Red Hat Text",
    "Baloo 2",
    "Handlee",
    "Patrick Hand",
    "Viga",
    "Ultra",
    "Faustina",
    "Gudea",
    "JetBrains Mono",
    "Sofia",
    "Voltaire",
    "DM Mono",
    "Vidaloka",
    "Zen Maru Gothic",
    "Albert Sans",
    "Epilogue",
    "Petrona",
    "Hammersmith One",
    "Ropa Sans",
    "Bad Script",
    "Neucha",
    "Lalezar",
    "Parisienne",
    "Marck Script",
    "Comic Neue",
    "Pridi",
    "Press Start 2P",
    "Hind Vadodara",
    "Baskervville",
    "PT Mono",
    "Saira Semi Condensed",
    "Six Caps",
    "Monda",
    "Shippori Mincho",
    "Castoro",
    "Volkhov",
    "Audiowide",
    "Cousine",
    "Antonio",
    "Forum",
    "Homemade Apple",
    "Sorts Mill Goudy",
    "Mandali",
    "Krub",
    "Blinker",
    "Fredoka",
    "Mada",
    "Actor",
    "Electrolize",
    "Monoton",
    "Secular One",
    "Reenie Beanie",
    "STIX Two Text",
    "Lusitana",
    "Alex Brush",
    "Playball",
    "Ruda",
    "Quantico",
    "Alexandria",
    "Jura",
    "Bai Jamjuree",
    "Nanum Pen Script",
    "Pragati Narrow",
    "Share Tech Mono",
    "Mr Dafoe",
    "Julius Sans One",
    "Istok Web",
    "Nothing You Could Do",
    "Rock Salt",
    "Sriracha",
    "Londrina Solid",
    "Eczar",
    "Laila",
    "Varela",
    "Kumbh Sans",
    "League Gothic",
    "BIZ UDPGothic",
    "Armata",
    "Fira Mono",
    "Fugaz One",
    "Pangolin",
    "Geologica",
    "Cabin Condensed",
    "Koulen",
    "Syne",
    "Anonymous Pro",
    "Gilda Display",
    "VT323",
    "Sansita",
    "BenchNine",
    "Italianno",
    "Jaldi",
    "Cutive Mono",
    "Shrikhand",
    "Newsreader",
    "Alef",
    "Libre Bodoni",
    "Creepster",
    "Niramit",
    "Macondo",
    "Pinyon Script",
    "Khula",
    "Days One",
    "Basic",
    "Black Han Sans",
    "Pontano Sans",
    "Playfair",
    "Damion",
    "Saira Extra Condensed",
    "Calistoga",
    "Rufina",
    "GFS Didot",
    "Leckerli One",
    "Kurale",
    "Sarala",
    "Alatsi",
    "Short Stack",
    "Graduate",
    "Sofia Sans Condensed",
    "Holtwood One SC",
    "Amita",
    "Rammetto One",
    "Kreon",
    "Economica",
    "Martel Sans",
    "Quintessential",
    "Covered By Your Grace",
    "Alumni Sans",
    "Dela Gothic One",
    "Changa One",
    "Julee",
    "Atkinson Hyperlegible",
    "Palanquin",
    "Charm",
    "Averia Serif Libre",
    "Racing Sans One",
    "PT Serif Caption",
    "Ramabhadra",
    "Anuphan",
    "Lemonada",
    "Cabin Sketch",
    "Berkshire Swash",
    "Big Shoulders Display",
    "K2D",
    "Aclonica",
    "Karma",
    "Just Me Again Down Here",
    "Suez One",
    "Caveat Brush",
    "Chewy",
    "Glegoo",
    "Pathway Extreme",
    "Coda",
    "Yrsa",
    "Trocchi",
    "Cormorant Infant",
    "Candal",
    "Andika",
    "IBM Plex Sans Thai",
    "Cinzel Decorative",
    "Lustria",
    "Hanken Grotesk",
    "Arapey",
    "Antic",
    "Hind Guntur",
    "Reem Kufi",
    "Athiti",
    "Salsa",
    "Belleza",
    "Michroma",
    "Sintony",
    "Fredericka the Great",
    "Zen Old Mincho",
    "Kiwi Maru",
    "Baloo Bhaijaan 2",
    "Gochi Hand",
    "Rozha One",
    "Syncopate",
    "Pattaya",
    "Sofia Sans",
    "Mali",
    "Nanum Brush Script",
    "Oxanium",
    "Marmelad",
    "Just Another Hand",
    "Balsamiq Sans",
    "Scada",
    "Allerta Stencil",
    "Arizonia",
    "Seaweed Script",
    "Cantata One",
    "Rubik Moonrocks",
    "Jua",
    "Judson",
    "Squada One",
    "Do Hyeon",
    "Shadows Into Light Two",
    "Annie Use Your Telescope",
    "Irish Grover",
    "Oranienbaum",
    "Pirata One",
    "Marcellus SC",
    "Palanquin Dark",
    "Akshar",
    "Brygada 1918",
    "Bevan",
    "Boogaloo",
    "Vazirmatn",
    "BioRhyme",
    "Wallpoet",
    "Mrs Saint Delafield",
    "Radio Canada",
    "Maitree",
    "Averia Libre",
    "Contrail One",
    "M PLUS 1",
    "Podkova",
    "Bellefair",
    "Darker Grotesque",
    "Grandstander",
    "David Libre",
    "Nobile",
    "Telex",
    "Delius",
    "Golos Text",
    "Aldrich",
    "Allerta",
    "Monsieur La Doulaise",
    "Rye",
    "Herr Von Muellerhoff",
    "Coming Soon",
    "Biryani",
    "Lateef",
    "Alike",
    "Hanuman",
    "Limelight",
    "Big Shoulders Text",
    "Baloo Da 2",
    "Bowlby One SC",
    "Caudex",
    "Rancho",
    "Gayathri",
    "Grand Hotel",
    "Aladin",
    "Arbutus Slab",
    "Kristi",
    "Cedarville Cursive",
    "Metrophobic",
    "Krona One",
    "Georama",
    "Corben",
    "Trirong",
    "Henny Penny",
    "La Belle Aurore",
    "Yesteryear",
    "Markazi Text",
    "Bowlby One",
    "Rambla",
    "Ephesis",
    "Average Sans",
    "Spinnaker",
    "Livvic",
    "Vollkorn SC",
    "UnifrakturMaguntia",
    "Chonburi",
    "Sigmar One",
    "Zen Antique",
    "Overlock",
    "Niconne",
    "Gurajada",
    "Kdam Thmor Pro",
    "Mallanna",
    "Manjari",
    "Overpass Mono",
    "B612 Mono",
    "Arya",
    "Nixie One",
    "Knewave",
    "Kosugi",
    "IM Fell English SC",
    "Encode Sans Semi Condensed",
    "Amethysta",
    "Kameron",
    "Ibarra Real Nova",
    "Ovo",
    "Odibee Sans",
    "Halant",
    "Allison",
    "Klee One",
    "Miriam Libre",
    "Grenze Gotisch",
    "Coustard",
    "Bungee Inline",
    "Magra",
    "Glory",
    "Cormorant Upright",
    "Jockey One",
    "Alegreya SC",
    "Amiko",
    "B612",
    "IBM Plex Sans KR",
    "Shippori Mincho B1",
    "Aboreto",
    "Bubblegum Sans",
    "Rochester",
    "Average",
    "Enriqueta",
    "Fanwood Text",
    "Petit Formal Script",
    "Protest Revolution",
    "Homenaje",
    "Calligraffitti",
    "Rasa",
    "Croissant One",
    "Turret Road",
    "IM Fell English",
    "Mina",
    "Fjord One",
    "Gabriela",
    "Italiana",
    "Rosario",
    "Kufam",
    "Della Respira",
    "Mansalva",
    "Poller One",
    "Montserrat Subrayada",
    "Love Ya Like A Sister",
    "Stardos Stencil",
    "Tomorrow",
    "IM Fell DW Pica",
    "Sniglet",
    "Inria Serif",
    "Nova Square",
    "Brawler",
    "Spectral SC",
    "Thasadith",
    "Waiting for the Sunrise",
    "RocknRoll One",
    "Zen Kaku Gothic Antique",
    "Oxygen Mono",
    "Chivo Mono",
    "Bungee Shade",
    "Kaisei Decol",
    "Lemon",
    "Molengo",
    "Young Serif",
    "Carrois Gothic",
    "M PLUS 2",
    "Dawning of a New Day",
    "Elsie",
    "Chelsea Market",
    "NTR",
    "Quando",
    "Fahkwang",
    "Kelly Slab",
    "Style Script",
    "Schoolbell",
    "DotGothic16",
    "Bentham",
    "Sofia Sans Extra Condensed",
    "Cambay",
    "Goudy Bookletter 1911",
    "Azeret Mono",
    "Instrument Sans",
    "ZCOOL XiaoWei",
    "Gabarito",
    "Proza Libre",
    "Vesper Libre",
    "Hahmlet",
    "Antic Didone",
    "Copse",
    "Ma Shan Zheng",
    "Gloock",
    "MuseoModerno",
    "Fondamento",
    "Trykker",
    "Wix Madefor Text",
    "Schibsted Grotesk",
    "Radley",
    "Sue Ellen Francisco",
    "Tillana",
    "Cormorant SC",
    "Federo",
    "Fauna One",
    "Goblin One",
    "Cutive",
    "Meddon",
    "Baloo Paaji 2",
    "Slabo 13px",
    "Ms Madi",
    "Jomhuria",
    "Alike Angular",
    "Zen Kurenaido",
    "Aref Ruqaa",
    "Norican",
    "Modak",
    "Rakkas",
    "Sansita Swashed",
    "Oleo Script Swash Caps",
    "Capriola",
    "Baloo Thambi 2",
    "Kadwa",
    "KoHo",
    "Lexend Zetta",
    "Baloo Tamma 2",
    "Marvel",
    "Share",
    "Headland One",
    "Hepta Slab",
    "Bellota Text",
    "Oregano",
    "Germania One",
    "Nova Mono",
    "Amarante",
    "Oooh Baby",
    "Gravitas One",
    "Qwigley",
    "BIZ UDGothic",
    "Ruslan Display",
    "Padauk",
    "Rouge Script",
    "Prosto One",
    "Dongle",
    "Mouse Memoirs",
    "Cambo",
    "Metamorphous",
    "Sedgwick Ave",
    "Gafata",
    "Supermercado One",
    "Notable",
    "Inknut Antiqua",
    "Lexend Exa",
    "Caladea",
    "Shantell Sans",
    "Mountains of Christmas",
    "Yusei Magic",
    "Kodchasan",
    "Freehand",
    "Sumana",
    "Duru Sans",
    "Anaheim",
    "Hurricane",
    "Besley",
    "Meie Script",
    "Megrim",
    "Delius Unicase",
    "Give You Glory",
    "Flamenco",
    "Coiny",
    "Montez",
    "Onest",
    "Hi Melody",
    "Poly",
    "Averia Sans Libre",
    "Mako",
    "Syne Mono",
    "Red Rose",
    "ZCOOL QingKe HuangYou",
    "Saira Stencil One",
    "Happy Monkey",
    "Skranji",
    "Encode Sans Semi Expanded",
    "Walter Turncoat",
    "Fuggles",
    "Over the Rainbow",
    "Vina Sans",
    "Sulphur Point",
    "Spline Sans",
    "Goldman",
    "Fresca",
    "Original Surfer",
    "Abyssinica SIL",
    "Asul",
    "Tenali Ramakrishna",
    "Pompiere",
    "Aguafina Script",
    "Mukta Vaani",
    "Wix Madefor Display",
    "Loved by the King",
    "Crafty Girls",
    "Mirza",
    "Gotu",
    "Euphoria Script",
    "Andada Pro",
    "Mr De Haviland",
    "Bungee Hairline",
    "Suranna",
    "Allan",
    "Carme",
    "Sail",
    "Expletus Sans",
    "Baloo Chettan 2",
    "IBM Plex Sans JP",
    "Lekton",
    "Geo",
    "Shalimar",
    "Ledger",
    "BhuTuka Expanded One",
    "IM Fell Double Pica",
    "Inder",
    "Familjen Grotesk",
    "Trade Winds",
    "Puritan",
    "Bigshot One",
    "Galada",
    "Mukta Mahee",
    "Libre Caslon Display",
    "Rampart One",
    "Buenard",
    "Codystar",
    "Clicker Script",
    "Bakbak One",
    "Esteban",
    "Hina Mincho",
    "Gugi",
    "Bayon",
    "Kranky",
    "Xanh Mono",
    "Battambang",
    "Wendy One",
    "Frijole",
    "Murecho",
    "Convergence",
    "Flow Circular",
    "Barriecito",
    "Farro",
    "Whisper",
    "Numans",
    "Kenia",
    "Artifika",
    "Dokdo",
    "Kaisei Opti",
    "Sarpanch",
    "Baloo Bhai 2",
    "Vast Shadow",
    "Lily Script One",
    "BIZ UDPMincho",
    "Libre Barcode 39 Text",
    "Anek Malayalam",
    "McLaren",
    "Montaga",
    "League Script",
    "Gantari",
    "Gaegu",
    "Timmana",
    "Manuale",
    "Fontdiner Swanky",
    "Gluten",
    "Poltawski Nowy",
    "Harmattan",
    "Almendra",
    "Vibur",
    "Marhey",
    "Orelega One",
    "Encode Sans Expanded",
    "Mochiy Pop One",
    "Sevillana",
    "Finger Paint",
    "Solway",
    "Zen Antique Soft",
    "Ceviche One",
    "Mogra",
    "Cormorant Unicase",
    "WindSong",
    "Baumans",
    "Overlock SC",
    "Share Tech",
    "Medula One",
    "Reggae One",
    "Slackey",
    "Anta",
    "MedievalSharp",
    "Eater",
    "Road Rage",
    "Akaya Kanadaka",
    "Bricolage Grotesque",
    "Cherry Cream Soda",
    "Major Mono Display",
    "Atma",
    "Kalnia",
    "Comforter Brush",
    "Potta One",
    "Unkempt",
    "Protest Riot",
    "Scope One",
    "Bellota",
    "Birthstone",
    "Emilys Candy",
    "Gamja Flower",
    "IBM Plex Sans Hebrew",
    "Nova Round",
    "The Girl Next Door",
    "Balthazar",
    "Tienne",
    "Tilt Warp",
    "Stick",
    "Smooch Sans",
    "Pavanam",
    "Corinthia",
    "Wire One",
    "Bilbo Swash Caps",
    "Doppio One",
    "Charmonman",
    "Fragment Mono",
    "Scheherazade New",
    "Monomaniac One",
    "Macondo Swash Caps",
    "Freckle Face",
    "Shanti",
    "Kode Mono",
    "Patrick Hand SC",
    "Nokora",
    "Chango",
    "ZCOOL KuaiLe",
    "Madimi One",
    "Mochiy Pop P One",
    "Delius Swash Caps",
    "Kaisei Tokumin",
    "Imprima",
    "Shojumaru",
    "Fasthand",
    "Londrina Outline",
    "Libre Barcode 128",
    "Life Savers",
    "Modern Antiqua",
    "Galdeano",
    "Instrument Serif",
    "Dynalight",
    "Ribeye",
    "Hachi Maru Pop",
    "Gowun Dodum",
    "Belgrano",
    "Libre Barcode 39 Extended Text",
    "ADLaM Display",
    "Orienta",
    "Tiro Kannada",
    "Meera Inimai",
    "Chau Philomene One",
    "Ruthie",
    "Baloo Bhaina 2",
    "Agbalumo",
    "Varta",
    "Anek Tamil",
    "Rubik Dirt",
    "Voces",
    "Recursive",
    "Ranga",
    "Gowun Batang",
    "Spicy Rice",
    "Katibeh",
    "Rosarivo",
    "Peralta",
    "REM",
    "Rhodium Libre",
    "Square Peg",
    "Anek Bangla",
    "Redressed",
    "Prociono",
    "Miniver",
    "Faster One",
    "Pixelify Sans",
    "Monofett",
    "Qwitcher Grypen",
    "Rum Raisin",
    "Anek Latin",
    "Cherry Swash",
    "Song Myung",
    "Namdhinggo",
    "Asar",
    "Gulzar",
    "Stalemate",
    "Suwannaphum",
    "Sofia Sans Semi Condensed",
    "Libre Barcode 128 Text",
    "Ranchers",
    "Engagement",
    "Raleway Dots",
    "Kaisei HarunoUmi",
    "Dorsa",
    "IM Fell French Canon",
    "Smooch",
    "East Sea Dokdo",
    "Edu SA Beginner",
    "Tauri",
    "Crushed",
    "Gemunu Libre",
    "Iceberg",
    "Nosifer",
    "Zilla Slab Highlight",
    "Spline Sans Mono",
    "Swanky and Moo Moo",
    "Tiro Devanagari Hindi",
    "Alkalami",
    "Zen Dots",
    "IM Fell DW Pica SC",
    "Tektur",
    "Arima",
    "Iceland",
    "Denk One",
    "Montagu Slab",
    "Jomolhari",
    "Nova Flat",
    "Kite One",
    "Maiden Orange",
    "Strait",
    "Akaya Telivigala",
    "Alkatra",
    "Stylish",
    "MonteCarlo",
    "Ojuju",
    "IM Fell Great Primer",
    "IBM Plex Sans Thai Looped",
    "Port Lligat Sans",
    "Lovers Quarrel",
    "Fenix",
    "Barrio",
    "Bona Nova",
    "Uncial Antiqua",
    "Train One",
    "Bruno Ace SC",
    "Sancreek",
    "Meow Script",
    "Vujahday Script",
    "Metal Mania",
    "Shippori Antique",
    "Sree Krushnadevaraya",
    "Stick No Bills",
    "Cute Font",
    "Milonga",
    "Karantina",
    "Habibi",
    "Stint Ultra Condensed",
    "Ramaraja",
    "Sonsie One",
    "Gorditas",
    "Lexend Mega",
    "Dekko",
    "Jolly Lodger",
    "Yuji Syuku",
    "Viaoda Libre",
    "Sunshiney",
    "Kotta One",
    "Akronim",
    "Baloo Tammudu 2",
    "Paprika",
    "Farsan",
    "Rationale",
    "Girassol",
    "Mystery Quest",
    "Donegal One",
    "Anybody",
    "Beth Ellen",
    "Anek Devanagari",
    "Carrois Gothic SC",
    "Cantora One",
    "Red Hat Mono",
    "Moul",
    "Port Lligat Slab",
    "Caprasimo",
    "Piazzolla",
    "Asset",
    "IM Fell French Canon SC",
    "Yomogi",
    "Genos",
    "Eagle Lake",
    "Kulim Park",
    "Martian Mono",
    "Passions Conflict",
    "Smythe",
    "Kavivanar",
    "Kantumruy Pro",
    "Redacted",
    "Stoke",
    "IM Fell Great Primer SC",
    "Tiro Bangla",
    "Nova Slim",
    "Angkor",
    "Sarina",
    "Delicious Handrawn",
    "Alumni Sans Inline One",
    "Margarine",
    "Keania One",
    "Zhi Mang Xing",
    "Grape Nuts",
    "Offside",
    "Caesar Dressing",
    "Lexend Giga",
    "Moon Dance",
    "Bubbler One",
    "Unlock",
    "Protest Strike",
    "Khmer",
    "IM Fell Double Pica SC",
    "Nova Cut",
    "Sura",
    "Shippori Antique B1",
    "Astloch",
    "Chicle",
    "Carattere",
    "Beau Rivage",
    "Nova Oval",
    "Nova Script",
    "Srisakdi",
    "Trispace",
    "AR One Sans",
    "Underdog",
    "Text Me One",
    "Cagliostro",
    "Miltonian Tattoo",
    "Long Cang",
    "Averia Gruesa Libre",
    "New Tegomin",
    "Content",
    "Inika",
    "Licorice",
    "Comforter",
    "Englebert",
    "Vampiro One",
    "Finlandica",
    "Aoboshi One",
    "Miltonian",
    "Fascinate",
    "Marko One",
    "Junge",
    "Tilt Prism",
    "Tulpen One",
    "New Rocker",
    "Arbutus",
    "Sirin Stencil",
    "Sahitya",
    "Libre Barcode 39 Extended",
    "Liu Jian Mao Cao",
    "Plaster",
    "Poor Story",
    "Simonetta",
    "Glass Antiqua",
    "Jacques Francois",
    "Anek Telugu",
    "Praise",
    "Texturina",
    "Bilbo",
    "Spirax",
    "Black And White Picture",
    "Wellfleet",
    "Mohave",
    "Kirang Haerang",
    "Ravi Prakash",
    "Stint Ultra Expanded",
    "Fuzzy Bubbles",
    "BIZ UDMincho",
    "Seymour One",
    "Nerko One",
    "M PLUS 1 Code",
    "Romanesco",
    "Bahiana",
    "Linden Hill",
    "The Nautigal",
    "Condiment",
    "Bonheur Royale",
    "Tilt Neon",
    "Ysabeau Office",
    "Anek Gujarati",
    "Birthstone Bounce",
    "Akatab",
    "Autour One",
    "Piedra",
    "Carlito",
    "Lexend Peta",
    "Mrs Sheppards",
    "Belanosima",
    "Joti One",
    "Rubik Doodle Shadow",
    "Charis SIL",
    "Imbue",
    "Koh Santepheap",
    "Elsie Swash Caps",
    "Kumar One",
    "Sedgwick Ave Display",
    "Yeon Sung",
    "Princess Sofia",
    "Gentium Book Plus",
    "Lexend Tera",
    "Kumar One Outline",
    "Ruluko",
    "Smokum",
    "Yaldevi",
    "Benne",
    "Oldenburg",
    "Atomic Age",
    "Kavoon",
    "Fascinate Inline",
    "Afacad",
    "Waterfall",
    "Lakki Reddy",
    "Playpen Sans",
    "Bahianita",
    "Sono",
    "Water Brush",
    "Ysabeau",
    "Chela One",
    "Londrina Shadow",
    "Felipa",
    "Micro 5",
    "Zen Tokyo Zoo",
    "Bungee Spice",
    "Gupter",
    "Risque",
    "Flavors",
    "Preahvihear",
    "Chathura",
    "Dhurjati",
    "Ribeye Marrow",
    "Imperial Script",
    "Hanalei Fill",
    "Qahiri",
    "Jacques Francois Shadow",
    "Lancelot",
    "Galindo",
    "Braah One",
    "Grenze",
    "Emblema One",
    "Ewert",
    "Diplomata SC",
    "Festive",
    "Anek Gurmukhi",
    "Encode Sans SC",
    "DynaPuff",
    "Griffy",
    "Rethink Sans",
    "Labrada",
    "Chilanka",
    "Bungee Outline",
    "Amiri Quran",
    "Flow Rounded",
    "Solitreo",
    "Almendra SC",
    "Climate Crisis",
    "Phudu",
    "Ysabeau Infant",
    "Mea Culpa",
    "IBM Plex Sans Devanagari",
    "Gentium Plus",
    "Castoro Titling",
    "Dangrek",
    "BioRhyme Expanded",
    "Odor Mean Chey",
    "Annapurna SIL",
    "Revalia",
    "Snippet",
    "Gidugu",
    "Big Shoulders Stencil Text",
    "Anek Kannada",
    "Hedvig Letters Sans",
    "Lacquer",
    "Metal",
    "Joan",
    "Erica One",
    "Agdasima",
    "Gasoek One",
    "Combo",
    "Almendra Display",
    "Dr Sugiyama",
    "Federant",
    "Stalinist One",
    "Peddana",
    "Luxurious Script",
    "Flow Block",
    "Alumni Sans Collegiate One",
    "Ruge Boogie",
    "Slackside One",
    "Borel",
    "Londrina Sketch",
    "Rubik Glitch",
    "Big Shoulders Inline Text",
    "Foldit",
    "Chenla",
    "Konkhmer Sleokchher",
    "Victor Mono",
    "Comme",
    "Jim Nightshade",
    "Mr Bedfort",
    "Aubrey",
    "Inspiration",
    "Hubballi",
    "Edu TAS Beginner",
    "GFS Neohellenic",
    "Jacquarda Bastarda 9",
    "Butcherman",
    "Single Day",
    "Libre Barcode EAN13 Text",
    "Neonderthaw",
    "Big Shoulders Stencil Display",
    "Tiro Telugu",
    "Rubik Iso",
    "Tiro Devanagari Marathi",
    "Siemreap",
    "Bigelow Rules",
    "Bokor",
    "Passero One",
    "Caramel",
    "Cairo Play",
    "Gideon Roman",
    "Sometype Mono",
    "Diplomata",
    "Lumanosimo",
    "Ballet",
    "Truculenta",
    "Rubik Wet Paint",
    "Send Flowers",
    "Yuji Mai",
    "Gwendolyn",
    "Luxurious Roman",
    "Mooli",
    "Reem Kufi Fun",
    "Anek Odia",
    "Babylonica",
    "Love Light",
    "Trochut",
    "Ysabeau SC",
    "Bagel Fat One",
    "Lavishly Yours",
    "Devonshire",
    "Bruno Ace",
    "Sofadi One",
    "Fruktur",
    "Suravaram",
    "Aref Ruqaa Ink",
    "Miss Fajardose",
    "Mynerve",
    "Purple Purse",
    "Inclusive Sans",
    "Blaka",
    "Island Moments",
    "Hedvig Letters Serif",
    "Uchen",
    "Butterfly Kids",
    "Tai Heritage Pro",
    "Yuji Boku",
    "Tiro Tamil",
    "Rubik Distressed",
    "Nuosu SIL",
    "Rock 3D",
    "Tourney",
    "Protest Guerrilla",
    "Moulpali",
    "Handjet",
    "Honk",
    "Snowburst One",
    "Vibes",
    "Bonbon",
    "Tsukimi Rounded",
    "Nabla",
    "Taprom",
    "My Soul",
    "Rubik Vinyl",
    "Fleur De Leah",
    "Syne Tactile",
    "Tiro Devanagari Sanskrit",
    "Bacasime Antique",
    "Updock",
    "Langar",
    "Explora",
    "Dai Banna SIL",
    "Cherry Bomb One",
    "Darumadrop One",
    "Rubik Beastly",
    "Redacted Script",
    "Edu NSW ACT Foundation",
    "Splash",
    "Moirai One",
    "Oi",
    "Reem Kufi Ink",
    "Edu VIC WA NT Beginner",
    "Kolker Brush",
    "Grechen Fuemen",
    "Geostar",
    "Sassy Frass",
    "Geostar Fill",
    "Petemoss",
    "Alumni Sans Pinstripe",
    "Sigmar",
    "Big Shoulders Inline Display",
    "Sixtyfour", 
    "Gajraj One",
    "Rubik Maps",
    "Are You Serious",
    "Edu QLD Beginner",
    "Zen Loop", 
    "Grey Qo",
    "Orbit", 
    "Lisu Bosa",
    "Palette Mosaic",
    "Diphylleia",
    "Rubik Glitch Pop", 
    "Tiro Gurmukhi", 
    "Ruwudu", 
    "Kablammo", 
    "Rubik Pixels",
    "Rubik 80s Fade",
    "Wavefont",
    "Rubik Microbe",
    "Lugrasimo",
    "Twinkle Star",
    "Warnes",
    "Rubik Spray Paint",
    "Shizuru",
    "Rubik Scribble",
    "Blaka Hollow",
    "Ole",
    "Estonia",
    "Tapestry",
    "Cherish",
    "M PLUS Code Latin",
    "Moo Lah Lah",
    "Workbench",
    "Rubik Burned",
    "Hanalei",
    "Kings",
    "Rubik Puddles",
    "Grandiflora One",
    "Blaka Ink",
    "Rubik Storm",
    "Lunasima",
    "Rubik Gemstones",
    "Rubik Broken Fax",
    "Rubik Marker Hatch", 
    "Ingrid Darling", 
    "Yuji Hentaigana Akari", 
    "Rubik Lines",
    "Puppies Play",
    "Rubik Maze", 
    "Rubik Doodle Triangles", 
    "Kay Pho Du", 
    "Linefont", 
    "Narnoor", 
    "Chokokutai", 
    "Mingzat", 
    "Yuji Hentaigana Akebono",
    "Padyakke Expanded One", 
]),
    googleFontsChanged = mobx.observable({
        value: !1
    });

function isGoogleFontChanged() {
    return googleFontsChanged.value
}

function setGoogleFonts(e) {
    "default" !== e ? (googleFontsChanged.value = !0, GOOGLE_FONTS.splice(0, GOOGLE_FONTS.length), GOOGLE_FONTS.push(...e)) : googleFontsChanged.value = !1
}

function getFontsList() {
    return GOOGLE_FONTS
}

function addGlobalFont(e) {
    exports.globalFonts.push(e)
}

function removeGlobalFont(e) {
    const t = exports.globalFonts.findIndex((t => t.fontFamily === e)); - 1 !== t && exports.globalFonts.splice(t, 1)
}

function replaceGlobalFonts(e) {
    exports.globalFonts.replace(e)
}
exports.isGoogleFontChanged = isGoogleFontChanged, exports.setGoogleFonts = setGoogleFonts, exports.getFontsList = getFontsList, exports.globalFonts = mobx.observable([]), exports.addGlobalFont = addGlobalFont, exports.removeGlobalFont = removeGlobalFont, exports.replaceGlobalFonts = replaceGlobalFonts;
var TEXT_TEXT = "Some test text;?#D-ПРИВЕТ!1230o9u8i7y6t5r4e3w2q1",
    canvas = document.createElement("canvas");

function measureArial() {
    return measureFont("Arial")
}

function measureTimes() {
    return measureFont("Times", "Times")
}

function measureFont(e, t = "Arial") {
    var o = canvas.getContext("2d");
    return o.font = `normal 40px '${e}', ${t}`, o.measureText(TEXT_TEXT).width
}
const MAX_ATTEMPTS = 100,
    loadedFonts = {
        Arial: !0
    },
    isFontLoaded = e => !!loadedFonts[e];
async function loadFont(e) {
    var t;
    if (loadedFonts[e]) return;
    const o = !!(null === (t = document.fonts) || void 0 === t ? void 0 : t.load),
        n = measureArial();
    if (o) try {
        await document.fonts.load(`16px '${e}'`);
        const t = measureFont(e);
        if (n !== t) return void(loadedFonts[e] = !0)
    } catch (e) {}
    const s = measureTimes(),
        r = measureFont(e);
    for (let t = 0; t < 100; t++) {
        const t = measureFont(e, "Arial"),
            o = measureFont(e, "Times");
        if (t !== r || t !== n || o !== s) return void(loadedFonts[e] = !0);
        await new Promise((e => setTimeout(e, 60)))
    }
    console.warn(`Timeout for loading font "${e}"`), (0, loader_1.triggerLoadError)(`Timeout for loading font "${e}"`)
}
exports.isFontLoaded = isFontLoaded, exports.loadFont = loadFont;
const registeredGoogleFonts = {};
let googleFontsVariants = "400,400italic,700,700italic";

function setGoogleFontsVariants(e) {
    googleFontsVariants = e
}

function injectGoogleFont(t) {
    if (registeredGoogleFonts[t]) return;
    const o = `https://fonts.googleapis.com/css?family=${t.replace(/ /g, "+")}:${googleFontsVariants}`,
        e = document.createElement("link");
    (e.type = "text/css"), (e.href = o), (e.rel = "stylesheet"), document.getElementsByTagName("head")[0].appendChild(e), (registeredGoogleFonts[t] = !0);
}
exports.setGoogleFontsVariants = setGoogleFontsVariants, exports.injectGoogleFont = injectGoogleFont;
const registeredCustomFonts = {};

function injectCustomFont(e) {
    const t = e.fontFamily;
    if (registeredCustomFonts[t]) return;
    if (!e.url && !e.styles) return;
    const o = document.createElement("style");
    o.type = "text/css";
    const n = e.styles || (e.url ? [{
        src: `url("${e.url}")`
    }] : []);
    let s = "";
    n.forEach((e => {
        s += `\n      @font-face {\n        font-family: '${t}';\n        src: ${e.src};\n        font-style: ${e.fontStyle||"normal"};\n        font-weight: ${e.fontWeight||"normal"};\n      }\n    `
    })), o.innerHTML = s, document.getElementsByTagName("head")[0].appendChild(o), registeredCustomFonts[e.fontFamily] = !0
}
exports.injectCustomFont = injectCustomFont;