"use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }), (exports.ImageElement = void 0);
const shape_model_1 = require("./shape-model");
exports.ImageElement = shape_model_1.Shape.named("Image")
    .props({ type: "image", width: 100, height: 100, src: "", cropX: 0, cropY: 0, cropWidth: 1, cropHeight: 1, cornerRadius: 0, flipX: !1, flipY: !1, clipSrc: "", borderColor: "black", borderSize: 0, keepRatio: !1, _cropModeEnabled: !1 })
    .actions((e) => ({
        toggleCropMode(o) {
            (e._cropModeEnabled = null != o ? o : !e._cropModeEnabled), e._cropModeEnabled ? e.store.history.startTransaction() : e.store.history.endTransaction();
        },
        beforeDestroy() {
            e._cropModeEnabled && e.store.history.endTransaction();
        },
    }));
