import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid } from 'maria/side-panel/images-grid';
import { getImageSize } from 'maria/utils/image';
import { Globals } from '../http';
import { Icon, IconSize } from "@blueprintjs/core";
export const ImagesPanel =  observer(({ store, type, tab }) => {
  const [images, setImages] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [query, setQuery] = React.useState('');
  const [totalPages, setTotalPages] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const loadImages = async  () => {
    // if reach the end, we stop
    if(totalPages!=0 && page>totalPages){
      return
    }
    // mark as loading
    setLoading(true)

    if((!query || (query && query.length < 2)) && type != 'uploaded')
    {
      setLoading(false);
      return;
    }
    // grab images
    const result = await fetch(`${Globals.api}/get-images?page=${page}&search=${query}&query=${type}`, {credentials:'include'}) 
    const data = await result.json();  
    for(let item of data.results) setImages(i=>[...i, item]);

    // finish loading
    setLoading(false)
    
    // set the total of pages accordingly
    if(page==1 && query == data.query) setTotalPages(data.total_pages)

    // increment page number
    setPage(p=>p+1)
  }

  // this is called when scrolled reached end aka infinite scroll
  const loadMore = (p) => {
    // if not already loading, start load images
    if(!loading) loadImages();
    return true;
  }
  // if user  typed enter we search
  const onSearchKeyDown = e => {
    setPage(1)
    
    if(e.key != 'Enter' ) return          
    setTotalPages(0)
    setImages([]);  
    loadImages(); 
  }
  
  const getPreview = i=>i.thumb;


  React.useEffect(() => { 
    loadImages();
  }, []);

  
  let t = null;
  
  // on image is clicked we add image to the page
  const onSelect = async (image, pos, element, event) => {
    
    try{
      if(image.object)
        {
          for(let o of image.object)
            {
              store.activePage.addElement(o);
            }
        
          return
        }
    }catch{}
    // get image size
    const { width, height } = await getImageSize(image.thumb);

    // if we selected a clip mask in page, then apply the image to the image pask
    if(window.lastSelectedElement.length > 0) {
      if (window.lastSelectedElement[0].clipSrc) {
        window.lastSelectedElement[0].set({src: image.regular})
        window.lastSelectedElement[0].toggleCropMode()
        return;
      }
    }
    // finally add the image to page
    store.activePage.addElement({
      type: 'image',
      src: image.regular,
      width: width*3,
      height: height*3,
      x: pos?.x || 0,
      y: pos?.y || 0,
    }); 
  };

  let uploadSection = '';
  if(type == 'uploaded')
    uploadSection = <UploadSection store={store} setImages={setImages} />;
  return (
    <div className="images"  >
      <div className='' >
      <FormGroup  labelFor="name"    >
      <InputGroup leftIcon="search" large={true}  placeholder="Cauta aici imaginea.." onValueChange={setQuery} onKeyUp={onSearchKeyDown}  rightElement={<Button icon="arrow-right" minimal={true} name="test"   />}  /> 
                    </FormGroup>
 
      </div>
      {uploadSection}
      <div className={ images.length>0 || type=='uploaded' || loading ?'d-none':'' }>
        <div className="p-3 text-center">
          <div className="bg-light rounded p-3  ">
            {'Caută imaginea dorita mai sus'}

            <div>
                <br />
              Încărca propriile imagini   
              <br />
              <br />
              <Button icon="cloud-upload" outlined={false}  intent='primary'   fill={true} text="Incarcă imagini aici" className="mb-2 rounded-pill " onClick={()=>{
                tab('uploaded')
              }} />

            </div>

          </div>
        </div>  
      </div>
      <div className={ images.length==0 && !loading && !query ?'d-none':'' }>

        <ImagesGrid images={images} getPreview={getPreview} rowsNumber={3} onSelect={onSelect} isLoading={loading} loadMore={loadMore} />
      </div>

     
    </div>
  )
})

export const PhotosPanel = observer(({ store }) => {
 
  const [tab, setTab] = React.useState("images");
  const changedTab = (id) => {
    setTab(id) 
  }
  return (
    <div style={{ height: '100%', display: 'flex', overflow:'auto', flexDirection: 'column' }} > 
    <Tabs id="TabsExample" renderActiveTabPanelOnly={true} large={true} onChange={changedTab} selectedTabId={tab}>
      <Tab id="images" title="Imagini" large={true}    icon="media" className="h-100" panel={<ImagesPanel tab={setTab} store={store} type="images" />} />
      <Tab id="uploaded" title="Incarcate" icon="cloud-upload" className="h-100" panel={<ImagesPanel tab={setTab}  store={store}  type="uploaded" />} />
      <TabsExpander />
    </Tabs>  
    
    </div>
  );
});
// define the new custom section
const PhotoSection = {
  name: 'photos',
  Tab: (props) => (
    <SectionTab name="Imagini" {...props}>
      
      <Icon icon="media" />
    </SectionTab>
  ),
  Panel: PhotosPanel,
};

export {PhotoSection};