import React from 'react';
import ReactDOM from 'react-dom/client';
import { MariaContainer, WorkspaceWrap } from 'maria'; 
import { Toolbar } from 'maria/toolbar/toolbar';
import { ZoomButtons } from 'maria/toolbar/zoom-buttons';
import { Workspace } from 'maria/canvas/workspace';


import '@blueprintjs/core/lib/css/blueprint.css';
import { observer } from 'mobx-react-lite';
import { createStore } from 'maria/model/store';
import { autorun } from "mobx"
import { Header } from './header/main.jsx'
import { Sidebar } from './sidebar.jsx'
import "./translations.jsx";
import "./style.jsx";
import "./custom.jsx";
import { useState } from 'react';
import {Save} from './saving.jsx'
import {onPageLoad} from "./load.jsx"
import {Project} from './project.jsx'

const store = createStore({
  key: 'test',  
  showCredit: false,

});
let project;
store.on('change', () => Save(store, project));

store.setRole('admin');


onPageLoad(store, project)

 
export const App = ({ store, saving }) => {
  project = new Project({ name:'Proiect nou' });
  
  return (
    <div >
      <Header store={store}  project={project} />
      <MariaContainer style={{ width: '100vw', height: 'calc(100dvh - 50px)' }}>
        <Sidebar store={store} />
        <WorkspaceWrap className="workspace-wrap">
          <Toolbar store={store} className="toolbar"  />
          <Workspace backgroundColor="#F4F3EF"  pageBorderColor="white" pageBorderWidth="1" activePageBorderColor="#e1ded0" store={store}   />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </MariaContainer>
    </div>

  );
};
 


import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App store={store}    />);