"use strict";
Object.defineProperty(exports, "__esModule", {
    value: !0
}), exports.setAPI = exports.iconscoutDownload = exports.iconscoutList = exports.svgapiDownload = exports.svgapiList = exports.unsplashDownload = exports.unsplashList = exports.textTemplateList = exports.templateList = exports.removeBackground = exports.mariaShapesList = exports.getGoogleFontImage = exports.getGoogleFontsListAPI = exports.URLS = exports.URL = exports.API = void 0;
const validate_key_1 = require("./validate-key");
exports.API = "/editor", exports.URL = "/";
const showedWarnings = {},
    warn = (e, o) => {
        showedWarnings[e] || (showedWarnings[e] = !0, console.error(o))
    },
    replaceAll = (e, o, t) => e.replace(new RegExp(o, "g"), t),
    ICONSCOUT_MESSAGE = "",
    UNSPLASH_MESSAGE = "";
    const params = new URLSearchParams(window.location.search);
exports.URLS = {
    unsplashList: ({
        query: e,
        page: o = 1
    }) => `${exports.API}/images?query=${e}&per_page=20&page=${o}&`,
    unsplashDownload: e => `${exports.API}/image?id=${e}&`,
    svgapiList: ({
        query: e,
        page: o = 0
    }) => `${exports.API}/get-svgapi?query=${e}&page=${o}&per_page=20&`,
    svgapiDownload: e => `${exports.API}/download-svgapi?path=${e}&`,
    iconscoutList: ({
        query: e,
        page: o = 1
    }) => (warn("iconscout", ICONSCOUT_MESSAGE), `${exports.API}/get-iconscout?query=${e}&page=${o}&`),
    iconscoutDownload: e => (warn("iconscout", ICONSCOUT_MESSAGE), `${exports.API}/download-iconscout?uuid=${e}&`),
    templateList: ({
        query: e,
        page: o = 1,
        sizeQuery: t
    }) => `${exports.API}/get-templates?${t}&query=${e}&item_id=${params.get('item_id')}&per_page=30&page=${o}&`
};
const getGoogleFontsListAPI = () => `${exports.API}/get-google-fonts?`;
exports.getGoogleFontsListAPI = getGoogleFontsListAPI;
const getGoogleFontImage = (e) => {
    return `https://static.consultadoctor.ro/fonts/images/${((t = e), (o = " "), (s = "-"), t.replace(new RegExp(o, "g"), s))}.png`;
    var t, o, s;
};
exports.getGoogleFontImage = getGoogleFontImage;
const mariaShapesList = () => `${exports.API}/get-basic-shapes?`;
exports.mariaShapesList = mariaShapesList;
const removeBackground = () => `${exports.API}/remove-image-background?`;
exports.removeBackground = removeBackground;
const templateList = e => exports.URLS.templateList(e);
exports.templateList = templateList;
const textTemplateList = () => `${exports.API}/get-text-templates?`;
exports.textTemplateList = textTemplateList;
const unsplashList = e => exports.URLS.unsplashList(e);
exports.unsplashList = unsplashList;
const unsplashDownload = e => exports.URLS.unsplashDownload(e);
exports.unsplashDownload = unsplashDownload;
const svgapiList = e => exports.URLS.svgapiList(e);
exports.svgapiList = svgapiList;
const svgapiDownload = e => exports.URLS.svgapiDownload(e);
exports.svgapiDownload = svgapiDownload;
const iconscoutList = e => exports.URLS.iconscoutList(e);
exports.iconscoutList = iconscoutList;
const iconscoutDownload = e => exports.URLS.iconscoutDownload(e);
exports.iconscoutDownload = iconscoutDownload;
const setAPI = (e, o) => {
    exports.URLS[e] = o
};
exports.setAPI = setAPI;