// pass properties of Konva.Transformer shape
import { setHighlighterStyle, setTransformerStyle } from 'maria/config';
setTransformerStyle({
  anchorStroke: '#008cff',
  borderStroke: '#008cff',
});

// pass properties of Konva.Rect node
setHighlighterStyle({
  stroke: '#008cff',
});
