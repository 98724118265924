import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {onSelectImage} from './select'

const ImageList =  observer(({ store, query, image}) => {
  var [images, setImages] = React.useState([]);
  var [page, setPage] = React.useState(1);
  var [totalPages, setTotalPages] = React.useState(0);
  var [loading, setLoading] = React.useState(true);

  const loadImages = async  (query) => {
      // if reach the end, we stop
    if(totalPages!=0 && page>totalPages){
      return
    }
    // mark as loading
    setLoading(true)

    // grab images
    const result = await fetch(`${Globals.api}${Globals.urls.listImage}?page=${page}&query=${query}`) 
    const data = await result.json();  
    console.log('data', data)
    for(let item of data.results) setImages(i=>[...i, item]);

    // finish loading
    setLoading(false)
    
    // set the total of pages accordingly
    if(page==1 && query == data.query) setTotalPages(data.total_pages)

    // increment page number
    setPage(p=>p+1)
  }

  // this is called when scrolled reached end aka infinite scroll
  const loadMore = (p) => {
    // if not already loading, start load images
    if(!loading) loadImages(query);
    return true;
  }
  

  React.useEffect( () => { 
    page = 1;
    setPage(o=>1)
    totalPages = 1;
    images=[];
    setImages(old=>[]);  
    loadImages(query);
  
}, [query, image]);

 

  
 
  return ( 
    <div  className="px-2 projects">
      <ImagesGrid shadowEnabled={false} images={images} getPreview={i=>i.thumbnail}   rowsNumber={4} onSelect={(i,p,e)=>onSelectImage(store,i,p,e)} isLoading={loading} loadMore={loadMore} />
    </div>
  )
})
 
 

export {ImageList};