import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputGroup, ControlGroup,  } from '@blueprintjs/core';
import { Tab, Tabs, TabsExpander, Button,FormGroup, OverlayToaster } from "@blueprintjs/core";
import { SidePanel, SectionTab } from 'maria/side-panel';
import { ImagesGrid, Image } from 'maria/side-panel/images-grid';
import { Globals } from '../../http';
import { Icon, IconSize } from "@blueprintjs/core";
import {onSelectImage} from './select'
import { PreviewPanel } from "../components/panel/preview";

const HomePanel =  observer(({ store, panel }) => {
  let [query, setQuery] = React.useState(panel.value.query);
  const [image, setImage] = React.useState({});
  const [elements, setElements] = React.useState([]);
  
  const onSearch = e=>{
    if(e.key != "Enter") return
    setQuery(old=>e.target.value)
    panel.set({query: e.target.value})
  }
  let i = 0;

 
   
  React.useEffect( ()=>{
    setTimeout(async () => {
      if(!query) query = '';
      const result = await fetch(`${Globals.api}${Globals.urls.listElements}?query=${query}`);
      const data = await result.json();
      console.log(data)
      setElements(data.results) 
    }, 1);
  }, [query])

  return (
    <div className="elements h-100"  >
        <div className="p-3">
          <FormGroup className="mb-0" labelFor="name" >
              <InputGroup leftIcon="search" large={true} defaultValue={query}  placeholder="Caută elemente aici..." onKeyUp={onSearch}  /> 
          </FormGroup>
        </div>
        <div className="overflow-auto"  style={{'height':'calc(100% - 70px)'}}>
          <div className="px-3">
            {elements?.map((item, index) => (
              <PreviewPanel store={store} item={item} panel={panel} />
            ))}
          </div>
        </div>
    </div>
  )
}) 
  
 
export {HomePanel};