import {Http, Globals } from './http'

let timeoutSave = null;
let saving = true;

const Save = (store, project)=>{
  store.setSaving(true);
  if(timeoutSave) clearTimeout(timeoutSave);
      timeoutSave = setTimeout(async () => {
      const temp_location = window.location.pathname
      const uid = temp_location.replace('/design/', '');
      const response = await Http.post(`${Globals.urls.saveProject}${uid}`, {
        store: await store.toJSON(),
        image: await store.toDataURL({ mimeType: 'image/jpeg', pixelRatio: 1 }),
        settings: project.value
      }) 
      
      store.setSaving(false);
    }, 2000);
}
export {Save};