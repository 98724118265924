import { getImageSize } from 'maria/utils/image';
const onSelectImage = async (store,image, pos, element, event) => {
    // get image size
    const { width, height } = await getImageSize(image.thumbnail);
    const ratio = store.activePage.width/width  / 2;
    store.activePage.addElement({
      type: image.type,
      src: image.original,
      width: width * ratio ,
      height: height * ratio ,
      x: pos?.x || 0,
      y: pos?.y || 0,
    }); 
  };
export {onSelectImage}