import {Http, Globals} from './http'
const temp_location = window.location.pathname
const uid = temp_location.replace('/design/', '');

const onPageLoad = async (store, project) =>{
  if(uid == '/') return;
  const response = await Http.get(`${Globals.urls.loadProject}${uid}`);
  store.loadJSON(response.store);
  store.toggleBleed(response.settings?.bleed?true:false)
  store.toggleRulers(response.settings?.rulers?true:false)
  console.log(project, response.settings)
  if(project)  project.set(response.settings)
}
export {onPageLoad};