function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
const csrftoken = getCookie('csrftoken');

const Globals = {
    url: 'https://editor.maju.ro',
    api: 'https://editor.maju.ro/api',
    urls: {
        createProject: '/project/create',
        loadProject: '/project/get/',
        saveProject: '/project/save/',
        uploadImage: '/image/upload',
        listImage: '/image/list',
        listProjects: '/project/list',

        listElements: '/element/list',
        viewElements: '/element/view/'
    },
    makeID: length => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    } 
}
if (process.env.NODE_ENV !== 'production') {
    Globals.api = 'http://localhost:8000/api';
    Globals.url = 'http://localhost:8000';
}
const Http = {
    get: async (url) => {
        const response = await fetch(`${Globals.api}${url}`, {
            headers: {'X-CSRFToken': csrftoken}
        })
        const json =  await response.json();
        json.status = response.status;
        return json;
    },
    post: async (url, data) => {
        const response = await fetch(`${Globals.api}${url}`,{
            
            method: 'POST',
            headers: {'X-CSRFToken': csrftoken},
            body: JSON.stringify(data)
        })
        let json = {status:response.status}
        try{json = {...json, ...await response.json()};}catch{}
       
        return json;
    }
}
export { Globals, Http};